/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Rails functionality
window.Rails = require("@rails/ujs");
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("trix");
require("@rails/actiontext");
//require("leaflet");
//require('leaflet/dist/leaflet.css')

// Tailwind CSS
import "stylesheets/application.scss";

// Stimulus controllers
import "controllers";

// Jumpstart Pro & other Functionality
import "src/actiontext";
import "src/confirm";
import "src/direct_uploads";
import "src/forms";
import "src/timezone";
import "src/tooltips";
import "src/john_hancock";
//import "src/maps"

import LocalTime from "local-time";
LocalTime.start();

// ADD YOUR JAVACSRIPT HERE

import L from "leaflet";
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

window.initMap = function (...args) {
  const event = document.createEvent("Events");
  event.initEvent("google-maps-callback", true, true);
  event.args = args;
  window.dispatchEvent(event);
};
// Start Rails UJS
Rails.start();
